.CompanySectors {
     margin-bottom: 10px;
}

.badgeCounter {
    margin-left: 5px;
}

.headerIcon {
    margin-right: 5px;
}

.pullRight {
    float: right;
}