.addbutton {
    text-align: center
}

.success-badge {
    margin-left: 10px;
    color: green;
    font-size: 20px;
}

.danger-badge {
    margin-left: 10px;
    color: red;
    font-size: 20px;
}

.deleteActorBtn {
    background-color: white !important;
    color: red;
    border: none;
    width: 5px;
}

.deleteActorBtn:hover {
    opacity: 0.5;
    color: red;
}

.actorTab {
    min-height: 30px;
}