.peoplePageMargins {
    margin-left: 30px;
    margin-right: 30px;
}

.addPersonContainer {
    margin-bottom: 50px;
}

.profilePic {
    max-width: 100px;
}